import React, { useMemo } from 'react'
import StatusBar from '../../widget/status-bar/Statusbar'
import { useTranslation } from 'react-i18next'
import ButtonView from './button-view'
import { copyTextToClipboard } from '../../../util/util'

const ActionsView = ({ data, viewData, resModel, refetch, actions, isForm, specification, formSubmitRef, isMainForm }) => {
    const { t } = useTranslation()
    const stateData = useMemo(() => {
        return viewData?.models?.[resModel]?.state?.selection || []
    }, [resModel])

    const submitForm = () => {
        if (isMainForm) return
        if (formSubmitRef?.current) {
            formSubmitRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
    }

    return (data?.state || actions.length > 0) && (
        <div className={`w-full items-center bg-white rounded-[10px] flex justify-between lg:flex-row md:flex-col grid-between gap-y-[15px] ${isForm && "border border-[#F2F2F2] p-6"}`}>
            {isForm && <div className='col-span-1'>
                <StatusBar statusOptions={stateData} name={''} defaultValue={data?.state} />
            </div>}

            <div className='lg:justify-end md:justify-start flex gap-2 flex-wrap ml-auto'>
                {actions?.map((action, index) => {
                    if (action?.widget === "CopyClipboardButton" && action?.link) return <button className='button-primary' onClick={() => copyTextToClipboard(action?.link)}>Sao chép</button>
                    if (data && action?.type_co === 'button') return (<ButtonView
                        refetch={refetch}
                        key={index}
                        resModel={resModel}
                        dataForm={data}
                        action={action}
                        context={data}
                        viewData={viewData}
                        specification={specification}
                        submitForm={submitForm}
                    />)

                })}
            </div>
        </div>)
}

export default ActionsView
