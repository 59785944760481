import React, { useEffect } from 'react'
import useCheckbox from '../model/checkbox'
import useTableStore from '../../../store/table'
import FieldView from '../../field/field-view'
import ModelService from '../../../core/services/model-service/model-service'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { VIEW_TYPE } from '../../../util/constant/view-type'
import ImageWithBasePath from '../../general/ImageCustom'
import { useTranslation } from 'react-i18next'
import TableGroup from './table-group'
import useSearchStore from '../../../store/search'
import { COMPONENT_TYPE } from '../../../util/constant/componen-type'

export const TableBodyRow = (props) => {
  const { columns, dataSource, isForm, rootField, model, dataResponseLength, columnWidths, funcRemoveLine, handleOnchange, viewData, resModel, visibleOptionalFields, typeTable, specification, type, append, level, showCheckBox, typeCo } = props
  const { t } = useTranslation()
  const { selectedRowKeys, setSelectedRowKeys, footerGroupTable, setFooterGroupTable } = useTableStore()
  const { handleCheckboxChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
  const { groupByDomain } = useSearchStore()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleUpdate = (record) => {
    if (!isForm && type !== "many2many") {
      searchParams.set('id', record?.id)
      const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
      navigate(_url)
    }
    else if (type === "many2many") {
      append(record)
    }
  }

  return dataSource && dataResponseLength > 0
    ? dataSource?.map((record, indexRow) => (
      typeTable === "group" ?
        <TableGroup
          dataResponseLength={dataResponseLength}
          rowRecord={record}
          columns={columns}
          indexRow={indexRow}
          rootField={rootField}
          isForm={isForm}
          resModel={model}
          viewData={viewData}
          visibleOptionalFields={visibleOptionalFields}
          specification={specification}
          domain={record.__domain}
          typeTable={groupByDomain?.values?.length > 1 ? "group" : "list"}
          columnWidths={columnWidths}
          type={type}
          level={level + 1}
          showCheckBox={showCheckBox}
        />
        : <tr
          key={record.id}
          className={`border-b border-[#e8e8e8] cursor-pointer ${selectedRowKeys.includes(record.id) ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-gray-50'
            } `}
        >
          {showCheckBox &&
            <td
              className={`${isForm ? 'hidden' : ''} w-12 cursor-pointer whitespace-nowrap p-3 text-sm font-medium text-gray-900`}
            >
              <input
                type='checkbox'
                checked={selectedRowKeys.includes(record.id)}
                onChange={(event) => handleCheckboxChange(event, record.id)}
                className='ml-2 align-middle custom-checkbox'
              />
            </td>
          }
          {columns?.map((col, index) => {
            return (
              <td
                key={col.key}
                className={`w-max whitespace-nowrap border-b border-gray-200 p-3 text-sm font-normal text-gray-900 !pr-0`}
                onClick={() => handleUpdate(record)}
                style={{ width: columnWidths[index] }}
              >
                <FieldView
                  {...col.field}
                  rootField={rootField}
                  index={indexRow}
                  defaultValue={record[col.key]}
                  onchangeData={ModelService.toDataJS(record, viewData, resModel)}
                  string=''
                  isForm={isForm}
                  handleOnchange={handleOnchange}
                />
              </td>
            )
          })}

          {isForm && typeCo !== COMPONENT_TYPE.TREE && <td className='relative p-2 w-[35px]'>
            <button type='button' className='size-8 p-2 border border-[DEE7DD] rounded-lg' onClick={() => funcRemoveLine(indexRow)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.9999 4.48633C13.9866 4.48633 13.9666 4.48633 13.9466 4.48633C10.4199 4.13299 6.89994 3.99966 3.41328 4.35299L2.05328 4.48633C1.77328 4.51299 1.52661 4.31299 1.49994 4.03299C1.47328 3.75299 1.67328 3.51299 1.94661 3.48633L3.30661 3.35299C6.85328 2.99299 10.4466 3.13299 14.0466 3.48633C14.3199 3.51299 14.5199 3.75966 14.4933 4.03299C14.4733 4.29299 14.2533 4.48633 13.9999 4.48633Z" fill="#ED1C24" />
                <path d="M5.66651 3.81301C5.63984 3.81301 5.61318 3.81301 5.57984 3.80634C5.31318 3.75967 5.12651 3.49967 5.17318 3.23301L5.31984 2.35967C5.42651 1.71967 5.57318 0.833008 7.12651 0.833008H8.87318C10.4332 0.833008 10.5798 1.75301 10.6798 2.36634L10.8265 3.23301C10.8732 3.50634 10.6865 3.76634 10.4198 3.80634C10.1465 3.85301 9.88651 3.66634 9.84651 3.39967L9.69984 2.53301C9.60651 1.95301 9.58651 1.83967 8.87984 1.83967H7.13318C6.42651 1.83967 6.41318 1.93301 6.31318 2.52634L6.15984 3.39301C6.11984 3.63967 5.90651 3.81301 5.66651 3.81301Z" fill="#ED1C24" />
                <path d="M10.1401 15.1667H5.8601C3.53343 15.1667 3.4401 13.88 3.36676 12.84L2.93343 6.12672C2.91343 5.85338 3.12676 5.61338 3.4001 5.59338C3.6801 5.58005 3.91343 5.78672 3.93343 6.06005L4.36676 12.7734C4.4401 13.7867 4.46676 14.1667 5.8601 14.1667H10.1401C11.5401 14.1667 11.5668 13.7867 11.6334 12.7734L12.0668 6.06005C12.0868 5.78672 12.3268 5.58005 12.6001 5.59338C12.8734 5.61338 13.0868 5.84672 13.0668 6.12672L12.6334 12.84C12.5601 13.88 12.4668 15.1667 10.1401 15.1667Z" fill="#ED1C24" />
                <path d="M9.10672 11.5H6.88672C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88672 10.5H9.10672C9.38005 10.5 9.60672 10.7267 9.60672 11C9.60672 11.2733 9.38005 11.5 9.10672 11.5Z" fill="#ED1C24" />
                <path d="M9.66683 8.83301H6.3335C6.06016 8.83301 5.8335 8.60634 5.8335 8.33301C5.8335 8.05967 6.06016 7.83301 6.3335 7.83301H9.66683C9.94016 7.83301 10.1668 8.05967 10.1668 8.33301C10.1668 8.60634 9.94016 8.83301 9.66683 8.83301Z" fill="#ED1C24" />
              </svg></button>
          </td>}
          {visibleOptionalFields && visibleOptionalFields.length > 0 && <td className='relative p-2 w-[35px]'></td>}
        </tr>
    ))
    : !isForm && (
      <tr>
        <td className='w-full' colSpan={columns?.length + 2}>
          <div className='my-20 flex flex-col items-center justify-center gap-3'>
            <ImageWithBasePath
              src='assets/img/empty_document.svg'
              alt='empty_document.svg'
              width={120}
              height={140}
              className='h-[140px] w-[120px]' />
            <div className='text-xl font-extrabold'>{t("create_document")}</div>
          </div>
        </td>
      </tr>
    )
}

const TableBody = (props) => {
  const { typeTable, dataSource, columns, level, objVal } = props
  const { setFooterGroupTable, fields } = useTableStore()

  useEffect(() => {
    if (typeTable === "group" && level === 0) {
      const newFooterGroupTable = {};
      dataSource?.forEach(rowRecord => {
        columns.forEach(col => {
          if (typeof rowRecord[col.key] === "number" || col.field.type === "monetary") {
            newFooterGroupTable[col.key] = (newFooterGroupTable[col.key] || 0) + rowRecord[col.key];
          }
        });
      });
      setFooterGroupTable(newFooterGroupTable);
    }
  }, [typeTable, fields]);
  return (
    <tbody>
      <TableBodyRow {...props} />
    </tbody >
  )
}

export default TableBody
