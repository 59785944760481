import Cookies from 'js-cookie'

export const CookieStorage = (function () {
  function _setAccessToken(accessToken, expires) {
    if (accessToken) {
      const days = expires / 60 / 60 / 24
      if (expires) {
        Cookies.set('accessToken', accessToken, {
          expires: days,
          path: '/',
        })
      } else {
        Cookies.set('accessToken', accessToken)
      }
    }
  }

  function _setRefreshToken(refreshToken) {
    if (refreshToken) {
      Cookies.set('refreshToken', refreshToken)
    }
  }

  function _setSetting(settingData) {
    if (settingData) {
      Cookies.set('settingData', JSON.stringify(settingData))
    }
  }

  function _getSetting() {
    return JSON.parse(Cookies.get('settingData') || '{}')
  }

  function _getAccessToken() {
    return Cookies.get('accessToken')
  }

  function _getRefreshToken() {
    return Cookies.get('refreshToken')
  }

  function _clearToken() {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    Cookies.remove('settingData')
  }

  function _setLanguage(lang) {
    if (lang) {
      Cookies.set('i18next', lang)
    }
  }

  function _getLanguage() {
    return Cookies.get('i18next')
  }

  return {
    setToken: _setAccessToken,
    setRefreshToken: _setRefreshToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setLanguage: _setLanguage,
    getLanguage: _getLanguage,
    setSetting: _setSetting,
    getSetting: _getSetting,
  }
})()
