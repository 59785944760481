import { useEffect, useState } from "react";
import { matchDomains } from "../../../core/domain/domain";
import { evalJSONContext, showErrorMessage, showSuccessMessage } from "../../../util/util";
import ActionService from "../../../core/services/action-service/action-service";
import Loading from "../../general/Loading";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHeaderStore from "../../../store/header";
import { useSearchParams } from "react-router-dom";

const ButtonView = ({ action, context, resModel, dataForm, refetch, viewData, specification, submitForm }) => {
  const [searchParams] = useSearchParams()
  const id = parseInt(searchParams.get('id'))
  const model = searchParams.get("model")
  const [isHidden, setHidden] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const contextAction = action?.context && evalJSONContext(action?.context, context)
  const { rootContext } = useHeaderStore()
  const { t } = useTranslation()

  // const methods = useFormContext()
  // const formValues = methods.watch()
  // const { dirtyFields } = methods.formState
  // const webSaveAction = async () => {
  //   const data = ModelService.parseORMOdoo(
  //     filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, dataForm)
  //   )
  //   const responseWebSaveAction = await ModelService.saveForm({
  //     ids: [],
  //     resModel: resModel,
  //     data: { ...dataForm, ...data },
  //     specification: specification,
  //     context: {
  //       ...rootContext,
  //       ...contextAction,
  //       active_id: id,
  //       active_ids: [id],
  //       active_model: model,
  //       allowed_company_ids: [1],
  //     },
  //   })

  //   return responseWebSaveAction
  // }

  useEffect(() => {
    if (action?.invisible) {
      setHidden(matchDomains({ ...context, ...rootContext, ...contextAction }, action.invisible))
    }
  }, [context])

  const callButton = useMutation({
    mutationFn: ({ method, resModel, ids, context }) => ActionService.callButton({
      method: method,
      resModel: resModel,
      ids: ids,
      context: context
    }),
    onSuccess: () => {
      refetch && refetch()
      showSuccessMessage(`${action?.string} ${t('thành công')}`)
    },
    onError: () => {
      showErrorMessage(`${action?.string} ${t('thất bại')}`)
    }
  })

  const handleButton = async () => {
    if (action?.type === 'object') {
      try {
        if (action?.confirm) {
          setOpenModal(true)
        } else {
          await submitForm()
          await callButton.mutateAsync({
            method: action?.name,
            resModel: resModel,
            // ids: dataForm?.id ? [dataForm?.id] : webSaveResponse[0]?.id ? [webSaveResponse[0]?.id] : [],
            ids: dataForm?.id ? [dataForm?.id] : [],
            context: {
              ...rootContext,
              ...contextAction,
              active_id: id,
              active_ids: [id],
              active_model: resModel
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    else if (action?.type === "cancel") {
      action?.onClose()
    }
  }

  return <>
    {/* {openModal && <ModalLayer isOpen={true} onClose={() => setOpenModal(false)} title="Xác nhận">
      <>
        <div className="flex gap-4 py-6">
          <p className="text-[20px]">Bạn không thể chuyển đổi loại chứng từ đã vào sổ.</p>
        </div>
        <div className="flex gap-3 w-full justify-end py-2">
          <button
            onClick={() => { }}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Đóng
          </button>
        </div>
      </>
    </ModalLayer>} */}
    <button
      type="button"
      onClick={() => handleButton()}
      className={`${action?.class?.includes('oe_highlight') || action?.class?.includes('btn-primary') ? 'button-primary' : 'button-secondary'} ${isHidden && 'hidden'}`}
    >
      {callButton.isLoading ? <Loading /> : action?.string}
    </button>
  </>
}

export default ButtonView