import FieldView from "../../field/field-view";
import GroupView from "../view/group-view";
import TreeViewForm from "../view/tree-view-form";

const useHandleField = ({ fields, viewData, resModel, onchangeData = {}, handleOnchange }) => {
    const fieldList = (fields ??= [])?.map((field, index) => {
        if (field?.type_co === "field") {
            const fieldDataRoot = viewData?.models?.[resModel]?.[field?.name] || {};
            const mergedField = { ...fieldDataRoot, onchangeData: onchangeData, ...field };
            return (
                <FieldView handleOnchange={handleOnchange} key={index} {...mergedField} isForm={true} />
            );
        }
        else if (field?.type_co === "group") {
            const mergeProps = { viewData: viewData, resModel: resModel, onchangeData: onchangeData, ...field }
            return (<GroupView handleOnchange={handleOnchange} key={index} {...mergeProps} />)
        }
        else if (field?.type_co === "tree") {
            return <TreeViewForm onchangeData={onchangeData} tab={field} viewData={viewData} resModel={resModel} key={index} />
        }
        else {
            return <></>
        }
    });

    return {
        fieldList,
    }
};

export default useHandleField