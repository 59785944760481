import { useSearchParams } from 'react-router-dom'
import Loading from '../../components/general/Loading'
import React, { useEffect, useState } from 'react'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONContext, evalJSONDomain, getSpecificationByFields } from '../../util/util'
import ModelService from '../../core/services/model-service/model-service'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useTable from '../../components/table/model/table'
import TableView from '../../components/table/view/table-view'
import useTableStore from '../../store/table'
import Breadcrumbs from '../../components/toolbar/view'
import useFormStore from '../../store/form'
import Pagination from '../../components/general/Pagination'
import useHeaderStore from '../../store/header'
import Excel from '../../components/excel'
import useSearchStore from '../../store/search'
import { useDebounce } from 'use-debounce'
import KanbanView from './kanbanView'

const TreeView = () => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get('vid')
  const resModel = searchParams.get("model")
  const { fields, setFields, page, setPage } = useTableStore()
  const { setViewDataStore, viewDataStore } = useFormStore()
  const [isFade, setIsFade] = useState(false);
  const { lang, rootContext } = useHeaderStore()
  const { setSelectedTags, groupByDomain, setGroupByDomain } = useSearchStore()
  const [debouncedPage] = useDebounce(page, 500);
  const [domainTable, setDomainTable] = useState([])
  const [isReady, setIsReady] = useState(false);
  const [viewType, setViewType] = useState("list")


  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}`, vid, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    refetchOnWindowFocus: false
  })
  const actionData = actionReponse?.[0]
  const actionContext = evalJSONContext(actionData?.context)

  const { data: viewResponse } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, lang, actionData?.id],
    queryFn: () => ViewServices.getFieldView({
      resModel: actionData?.res_model,
      views: [...actionData?.views,
      [actionData?.search_view_id, 'search']],
      context: { ...rootContext, ...actionContext }
    }),
    enabled: !!actionData,
    refetchOnWindowFocus: false
  })
  const viewData = viewResponse
  const domain = actionReponse?.[0]?.domain ? evalJSONDomain(actionReponse?.[0]?.domain) : []
  const specification = viewResponse && getSpecificationByFields(viewType == "kanban" ? viewResponse?.views?.kanban?.fields : viewResponse?.views?.tree?.fields, {}, viewData, resModel)

  const fetchData = async () => {
    try {
      setDomainTable(domain)
      sessionStorage.setItem('actionData', JSON.stringify(actionReponse?.[0]))
      sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      setViewDataStore(viewResponse)

      if (!fields?.[`${vid}_${resModel}`]) {
        setFields({ ...fields, [`${vid}_${resModel}`]: viewResponse?.views?.tree?.fields })
      }

      setPage(0)
      setSelectedTags([])
    } catch (err) {
      console.log(err);
    }
  }

  const { data: dataResponse, isLoading: loadingData, isFetched: isQueryFetched, isPlaceholderData, isSuccess, refetch } = useQuery({
    queryKey: [`data-${resModel}-${vid}`, specification, domainTable, debouncedPage, groupByDomain],
    queryFn: () => ModelService.getAll({ resModel: resModel, specification: specification, domain: domainTable, offset: debouncedPage * 10, fields: groupByDomain?.fields, groupby: [groupByDomain?.contexts[0]?.group_by], context: { ...rootContext, ...actionContext } }),
    enabled: isReady && !!specification && !!resModel && !!domainTable && !!viewDataStore,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  useEffect(() => {
    setGroupByDomain(null)
    setDomainTable([])
    setIsReady(true)
    if (viewResponse) {
      fetchData()
    }
  }, [viewResponse])

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsFade(true);
      }, 100);
    }
  }, [isSuccess])

  const context = actionData?.context ? evalJSONContext(actionData?.context) : {}

  const { dataSource, columns, visibleOptionalFields, typeTable } = useTable({
    model: resModel,
    fields: fields?.[`${vid}_${resModel}`],
    data: dataResponse?.records ?? dataResponse?.groups,
    dataModel: viewData?.models?.[resModel],
    context: { ...rootContext, ...context },
    typeTable: dataResponse?.groups ? "group" : "list"
  })
  return (
    <>
      {vid === "action" ?
        <Excel
        /> :
        <div className={`page-wrapper  ${isFade && "fade-in"}`}>
          {((!loadingData && isQueryFetched) || isPlaceholderData) ? (
            <div className="content">
              <Breadcrumbs
                title={actionData?.name}
                actionData={actionData}
                viewData={viewDataStore}
                dataToolbar={viewType == "kanban" ? viewDataStore?.views?.kanban?.toolbar : viewDataStore?.views?.tree?.toolbar}
                vid={vid}
                model={resModel}
                isInForm={false}
                funcRefetch={refetch}
                domain={domainTable}
                setDomain={setDomainTable}
                viewType={viewType}
                setViewType={setViewType}
                openView={viewResponse?.views}
              />
              <div className='card rounded-[10px] drop-shadow-[0px_1px_8px_rgba(82,88,102,0.06)] !border-[1.5px] !border-[#DEE7DD]'>
                <div className='card-body flex flex-col gap-[16px]'>
                  <div className={`relative w-full min-h-[60vh] `}>
                    {viewType == "kanban" ? (
                      <KanbanView data={dataSource} name={viewDataStore?.views?.kanban?.name} />
                    ) : (
                      <div className='flex w-full items-center overflow-x-scroll'>
                        <TableView
                          model={resModel}
                          dataSource={dataSource}
                          dataResponseLength={dataResponse?.length}
                          columns={columns}
                          visibleOptionalFields={visibleOptionalFields}
                          isForm={false}
                          typeTable={typeTable}
                          specification={specification}
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <Pagination
                className="pagination-bar mt-6 px-3 py-4"
                currentPage={page}
                totalCount={dataResponse?.length ?? 0}
                pageSize={10}
                onPageChange={page => setPage(page)}
                displayPageNumberDots={true}
              />
            </div>
          ) : (
            <Loading />
          )}

        </div>}</>

  )
}
export default TreeView
