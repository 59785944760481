import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useHandleField from '../../components/form/model/main'
import ActionsView from '../../components/form/view/action-view'
import MainForm from '../../components/form/view/main-form'
import Loading from '../../components/general/Loading'
import Breadcrumbs from '../../components/toolbar/view'
import ModelService from '../../core/services/model-service/model-service'
import { FIELD_TYPE } from '../../util/constant/field-type'
import { METHOD_TYPE, RELATION_TYPE } from '../../util/constant/method-type'
import { VIEW_TYPE } from '../../util/constant/view-type'
import { evalJSONContext, getSpecificationByFields, isValidJSON, showErrorMessage, showSuccessMessage } from '../../util/util'
import useFormStore from '../../store/form'

const Form = ({ resModel, actionData, viewData, id, vid, isDisplayBreadcrumbs = false, relationMappingData = {}, initialMappingData = {}, isNoteBookViewMode = false, isInModel = false, className,parentSpecification, isMainForm }) => {
  const [searchParams] = useSearchParams()
  const [onchangeData, setOnchangeData] = useState()
  const [objVal, setObjVal] = useState({})
  const [fieldChange, setFieldChange] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isFade, setIsFade] = useState(false)
  const nameAction = actionData?.name
  const contextAction = actionData?.context ? isValidJSON(actionData?.context) ? evalJSONContext(actionData?.context) : actionData?.context : {}
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const { setFormSubmitComponent } = useFormStore()
  const formSubmitRef = useRef(null)

  // fetch list field have onchange api and related
  const { data: fieldsOnchange } = useQuery({
    queryKey: [`field-onchange-${resModel}`, resModel],
    queryFn: () => ModelService.getFieldWithOnchange({ model: resModel }),
    refetchOnWindowFocus: false,
  })

  const toolbar = viewData?.views?.tree?.toolbar
  const dataModel = viewData?.models?.[resModel]
  const fields = viewData?.views?.form?.fields?.map((field) => ({ ...dataModel?.[field?.name], ...field }))

  const oeTitle = viewData?.views?.form?.oe_title || []
  const headerFiled = viewData?.views?.form?.header ? viewData?.views?.form?.header.filter((comp) => comp?.type_co === 'field') : []
  const tabFields = viewData?.views?.form?.tabs || []
  const specification =
    (viewData &&
      getSpecificationByFields(
        [...fields, ...oeTitle, ...headerFiled, ...tabFields],
        {},
        viewData,
        resModel
      ))

  const initVal = ModelService.toDataJS(onchangeData, viewData, resModel)
  const methods = useForm({
    mode: 'onBlur',
    values: isNoteBookViewMode ? { ...initVal, ...relationMappingData, ...initialMappingData } : initVal,
  })

  const formValues = methods.watch()
  const { dirtyFields } = methods.formState

  // filter field change
  const filterFieldDirty = (id, viewData, formValues, dirtyFields, resModel, defaultData) => {
    const data = id ? { ...dirtyFields } : { ...formValues }
    for (const key in data) {
      if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY)) {
        const lineData = [];
        (formValues[key] ??= []).forEach((itemData, index) => {
          if (!itemData?.id) {

            lineData.push([METHOD_TYPE.CREATE, `virtual_${index}`, filterFieldDirty(
              itemData?.id,
              viewData,
              itemData,
              {},
              viewData?.models?.[resModel]?.[key]?.relation
            )])
          } else {
            dirtyFields[key].forEach((itemDirty) => {
              lineData.push([
                METHOD_TYPE.UPDATE,
                itemData?.id,
                filterFieldDirty(
                  itemData?.id,
                  viewData,
                  itemData,
                  itemDirty,
                  viewData?.models?.[resModel]?.[key]?.relation
                ),
              ])
            })
          }
        });
        (defaultData[key] ??= []).forEach((item) => {
          if (!(formValues[key] ??= [])?.find((itemData) => itemData?.id === item?.id)) {
            lineData.push([METHOD_TYPE.DELETE, item?.id, item])
          }
        });

        data[key] = lineData
      } else if (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY) {
        const lineData = [];

        (formValues[key] ??= []).forEach((itemData, index) => {
          lineData.push([RELATION_TYPE.NO_CHANGE, itemData?.id])
        })
        data[key] = lineData
      }
      else if (id) {
        data[key] = formValues?.[key]
      }
    }

    return data
  }


  // fetch onchange default form data
  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeReponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: specification,
        context: context,
        object: objVal,
        fieldChange: fieldChange,
      })
      return onchangeReponse?.value ?? onchangeReponse?.[0]
    } catch (err) {
      console.log(err)
    }
  }

  // fetch detail data record
  const fetchDetailData = async () => {
    try {
      const dataResponse = await ModelService.getDetailData({
        model: resModel,
        ids: id ? [id] : [],
        specification: parentSpecification ?? specification,
        context: contextAction,
      })
      if (dataResponse.length > 0) {
        return dataResponse[0]
      }

      return {}
    } catch (err) {
      console.log(err)
    }
  }

  // get default form value 
  const { isLoading: isLoadingFields, isPending, isFetched: isQueryFetched, isFetching, refetch: refetchDataForm } = useQuery({
    queryKey: [`formData-${resModel}-${id}`, id, vid, resModel],
    queryFn: async () => {
      if (!id) {
        const onchangeResponse = await fetchOnchange({
          ids: id ? [id] : [],
          model: resModel,
          specification: specification,
          context: contextAction,
          object: objVal,
          fieldChange: fieldChange,
        });
        setOnchangeData(onchangeResponse)
      } else {
        const detailData = await fetchDetailData()
        setOnchangeData(detailData)
      }

      return {}
    },
    enabled: !!specification,
    refetchOnWindowFocus: false,
  })

  // handle submit form
  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const data = ModelService.parseORMOdoo(
        filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)
      )

      const response = await ModelService.saveForm({
        ids: id ? [id] : [],
        resModel: resModel,
        data: data,
        specification: specification,
        context: contextAction,
      })
      if (response && response.length > 0 && isMainForm) {
        searchParams.set('id', response?.[0]?.id)
        if (isInModel) {
          refetchDataForm()
        } else {
          const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
          navigate(_url)
        }
      }
      setLoading(false)
      showSuccessMessage(!id ? `${t('create_success')}` : `${t('update_success')}`)
    } catch (error) {
      console.log(error)
      setLoading(false)
      showErrorMessage(!id ? `${t('create_fail')}` : `${t('update_fail')}`)
    }
  }

  useEffect(() => {
    if (!isFetching && !isPending) {
      setTimeout(() => {
        setIsFade(true)
      }, 100)
    }
  }, [isFetching, isPending])

  //handle delete record
  const handleDelete = async () => {
    const result = window.confirm("Bạn có chắc chắn muốn xóa không?");
    if (result) {
      setLoadingDelete(true)
      try {
        await ModelService.deleteByForm({ ids: [id], model: resModel })
        setLoadingDelete(false)
        showSuccessMessage(`${t('delete_success')}`)
        navigate(`/list?vid=${vid}&model=${resModel}`);
      } catch (err) {
        setLoadingDelete(false)
        showErrorMessage(`${t('delete_fail')}`)
      }

    }
  }

  //handle onchange formValues
  const handleOnchange = (nameField, value) => {
    if (fieldsOnchange?.includes(nameField)) {
      const fetchData = async () => {
        const obj = {
          ...objVal,
          ...ModelService.parseORMOdoo(filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)),
          [nameField]: value
        }
        const dataOnchange = await fetchOnchange({
          id: id,
          model: resModel,
          specification: specification,
          context: contextAction,
          objVal: ModelService.parseORMOdoo(obj),
          fieldChange: [nameField]
        })

        const updatedData = { ...formValues, [nameField]: value, ...ModelService.toDataJS(dataOnchange) };
        const dataOnchangeJS = ModelService.toDataJS(updatedData, viewData, resModel)
        if (dataOnchangeJS) {
          Object.keys(dataOnchangeJS).forEach((key) => {
            if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
              methods.setValue(key, (dataOnchange[key] ??= []).map((item) => {
                if (item?.length >= 3) {

                  return ModelService.toDataJS(item[2], viewData, resModel)
                }
                return null
              }))
            } else {
              methods.setValue(key, dataOnchangeJS[key])
            }
          })
        }
      }
      fetchData()
    }
  }

  //get field items
  const { fieldList } = useHandleField({
    fields: (fields && oeTitle) && [...oeTitle, ...fields],
    viewData: viewData,
    resModel: resModel,
    onchangeData: formValues,
    handleOnchange: handleOnchange
  })

  useEffect(() => {
    setFormSubmitComponent(resModel, formSubmitRef)
  }, [formSubmitRef?.current])

  const onSubmit = async (e) => {
    e.stopPropagation()
    methods.handleSubmit(handleFormSubmit)()
  }

  return (
    <>
      {
        ((!isLoadingFields && isQueryFetched)) ?
          <FormProvider {...methods}>
            {isDisplayBreadcrumbs && <Breadcrumbs
              id={id}
              title={nameAction}
              subTitle={!id ? t('new') : formValues?.name || formValues?.id}
              viewData={viewData}
              actionData={actionData}
              dataToolbar={toolbar}
              vid={vid}
              model={resModel}
              isInForm={true}
              funcReset={() => {
                if (methods.formState.isDirty) {
                  methods.reset()
                  showSuccessMessage("Hoàn tác thành công")
                }
              }}
              loading={loading}
              loadingDelete={loadingDelete}
              onDelete={handleDelete}
              formSubmitRef={formSubmitRef}
              nameActionSave={id ? t('update_button') : t('save')}
              funcRefetch={refetchDataForm}
              formValues={formValues}
              formSpecification={specification}
            />
            }
            <form ref={formSubmitRef} className={`${isFade && "fade-in"} relative form`} onSubmit={(event) => { onSubmit(event) }}
            >
              <div className={`flex flex-col gap-2 md:!gap-4 w-full`}>
                {viewData?.views?.form?.header && viewData?.views?.form?.header?.length > 0 && <ActionsView
                  formSubmitRef={formSubmitRef}
                  isForm={true}
                  refetch={refetchDataForm}
                  actions={viewData?.views?.form?.header}
                  resModel={resModel}
                  viewData={viewData}
                  data={formValues}
                  isMainForm={isMainForm}
                />}
                <MainForm
                  id={id}
                  handleOnchange={handleOnchange}
                  fieldList={fieldList}
                  viewData={viewData}
                  onchangeData={formValues}
                  resModel={resModel}
                  context={actionData?.context}
                  title={nameAction}
                  className={className}
                />
                {viewData?.views?.form?.footer && viewData?.views?.form?.footer?.length > 0 && <ActionsView
                  isForm={false}
                  specification={specification}
                  actions={viewData?.views?.form?.footer ?? []}
                  resModel={resModel}
                  viewData={viewData}
                  data={formValues}
                  formSubmitRef={formSubmitRef}
                  isMainForm={isMainForm}
                />}
              </div>
            </form>
          </FormProvider> :
          <Loading />
      }
    </>
  )
}

export default Form
