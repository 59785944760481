import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import ViewServices from '../../../core/services/view-service/view-service'
import { buildUrl } from '../../../Router/url'
import { menu_focus } from '../../../util/redux/Action'
import ImageWithBasePath from '../../general/ImageCustom'
import { useMetaContext } from '../../../Router/MetaContext'
import useHeaderStore from '../../../store/header'
import useExcelStore from '../../../store/excel'
import useTableStore from '../../../store/table'
import { IoClose } from 'react-icons/io5'
import { MoreHorizontal } from 'react-feather'
import { useClickOutside } from '../../../hooks/useClickOutside'
import MenuItem from './MenuItem'
import useThemeStore from '../../../store/theme'
import useSidebar from '../../../store/sidebar'
import { SIDEBAR_TYPE } from '../../../util/constant/sidebar-type'

const Sidebar = ({ setToggleMenuMobile }) => {
  const [searchParams] = useSearchParams()
  const vid = parseInt(searchParams.get("vid"))
  const model = searchParams.get("model")
  let pathname = location.pathname
  const { metadata, loadingImport, openTouch, setOpenTouch, setSelectedFile } = useMetaContext()
  const { setIdFile, setIsFileLoaded } = useExcelStore()
  const { setSelectedRowKeys } = useTableStore()
  const { setMenuList } = useSidebar()
  const { layoutStyling, setLayoutStyling } = useThemeStore()
  const { lang } = useHeaderStore()
  const [openMenus, setOpenMenus] = useState({})
  const [menuFocus, setMenuFocus] = useState()
  const [toggle, setToggle] = useState(false)
  const [parentItem, setParentItem] = useState(null)
  const [isShowMoreMenu, setIsShowMoreMenu] = useState(false)
  const isHorizontal = layoutStyling === SIDEBAR_TYPE.HORIZONTAL

  const moreMenuRef = useClickOutside(() => {
    setIsShowMoreMenu(false)
  })

  const sidebarHorizontalRef = useClickOutside(() => {
    if (isHorizontal) {
      closeAllMenu()
    }
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: listMenu } = useQuery({
    queryKey: ['get_menu_view', lang],
    queryFn: () => ViewServices.getMenuView(lang),
    refetchOnWindowFocus: false,
  })

  const handleSidebar = (e) => {
    e.preventDefault()
    document.body.classList.toggle('mini-sidebar')
    setToggle((current) => !current)
    setOpenTouch(!openTouch)
  }

  const expandMenu = () => {
    document.body.classList.remove('expand-menu')
    // setHover(false)
  }

  const expandMenuOpen = () => {
    document.body.classList.add('expand-menu')
    // setHover(true)
  }

  const toggleMenu = (item, level) => {
    setOpenMenus((prev) => {
      const newOpenMenus = { ...prev }
      Object.keys(newOpenMenus).forEach((key) => {
        if (newOpenMenus[key] && key !== item.id.toString() && key.startsWith(`level-${level}-`)) {
          delete newOpenMenus[key]
        }
      })
      newOpenMenus[`level-${level}-${item.id}`] = !prev[`level-${level}-${item.id}`]

      setParentItem(item)
      return newOpenMenus
    })
  }

  const closeAllMenu = () => {
    setOpenMenus((prev) => {
      const newOpenMenus = { ...prev }
      Object.keys(prev).forEach((key) => {
        newOpenMenus[key] = false
      })
      return newOpenMenus
    })
    setIsShowMoreMenu(false)
  }

  useEffect(() => {
    const url_icon = sessionStorage.getItem('url_icon')
      ? JSON.parse(sessionStorage.getItem('url_icon'))
      : null;

    const parent_icon = sessionStorage.getItem('parent_icon')
      ? JSON.parse(sessionStorage.getItem('parent_icon'))
      : null;

    if (listMenu && !url_icon && !parent_icon) {
      const parent_icon = listMenu?.records[0]
      const url_icon = parent_icon.child_id[0]
      sessionStorage.setItem('parent_icon', JSON.stringify(parent_icon))
      sessionStorage.setItem('url_icon', JSON.stringify(url_icon))
    }

    const menuActive = url_icon ? url_icon : parent_icon

    setMenuFocus(menuActive?.id)
    setMenuList(listMenu?.records)

    if (layoutStyling && !isHorizontal) {
      const parentMenuActive = listMenu?.records?.find((item) => item.child_id.find((itemChild) => itemChild.id === menuActive?.id))
      parentMenuActive && toggleMenu(parentMenuActive, -1)
    }
  }, [listMenu])

  const handleNavigation = (item) => {
    setIdFile(null)
    setSelectedFile(null)
    setIsFileLoaded(false)
    setSelectedRowKeys([])
    const id = item?.action?.id?.id
    const model = item?.action?.res_model
    const view = item?.action?.binding_view_types?.split(',')[0]

    sessionStorage.setItem('parent_icon', JSON.stringify(parentItem))
    sessionStorage.setItem('url_icon', JSON.stringify(item))
    setParentItem(null)
    closeAllMenu()

    dispatch(menu_focus(id))
    navigate(buildUrl(view, id, model))
    setToggleMenuMobile(false)
  }

  const handleResize = () => {
    setToggleMenuMobile(window.innerWidth > 991);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    const defaultSidebar = searchParams.get("menu")
    setLayoutStyling(defaultSidebar === SIDEBAR_TYPE.DEFAULT ? defaultSidebar : process.env.REACT_APP_SIDEBAR_TYPE)

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const parentMenuActive = listMenu?.records?.find((item) => item?.child_id.find((itemChild) => itemChild?.action?.id?.id === vid))
    if (parentMenuActive) {
      const subMenuActive = parentMenuActive?.child_id?.find(item => item?.action?.id?.id === vid)
      setParentItem(parentMenuActive)
      setMenuFocus(subMenuActive?.id)
      sessionStorage.setItem('parent_icon', JSON.stringify(parentMenuActive))
      sessionStorage.setItem('url_icon', JSON.stringify(subMenuActive))
    }
  }, [vid, model])

  return (
    <div className='h-full '>
      <div
        className="sidebar lg:relative flex h-full flex-col"
        id='sidebar'
        onMouseLeave={expandMenu}
        onMouseOver={expandMenuOpen}
      >
        {/* Logo */}
        <div className={`header-left !px-4 ${toggle ? '' : 'active'}`}>
          <div
            className='logo logo-normal'
          >

            {metadata &&
              (!loadingImport ? (
                <div className='flex items-center justify-between gap-[16px]'>
                  <div className='flex h-[28px] w-[80px] items-start'>
                    <img
                      src={metadata?.logo}
                      alt='logo'
                      width={140}
                      height={48}
                      className='h-auto max-h-[35px] object-contain object-top'
                    />
                  </div>


                </div>
              ) : (
                <ImageWithBasePath src='../../../public/assets/logo/vitrust-white.svg' alt='img' />
              ))}
          </div>
          <button className="flex lg:hidden" onClick={() => setToggleMenuMobile(false)}>
            <IoClose size={25} />
          </button>
          <Link onClick={() => navigate('/list?vid=510&model=res.partner')} className='logo logo-white'>
            <ImageWithBasePath src='assets/logo/vitrust-three.svg' alt='img' />
          </Link>
          <Link
            onClick={() => navigate('/list?vid=510&model=res.partner')}
            className='logo-small'
          >
            {metadata?.logo && <ImageWithBasePath src={metadata?.logo} alt='img' />}
          </Link>
          <Link
            id='toggle_btn'
            to='#'
            style={{
              display:
                pathname.includes('tasks') || pathname.includes('pos')
                  ? 'none'
                  : pathname.includes('compose')
                    ? 'none'
                    : '',
            }}
            onClick={handleSidebar}
            className='flex !size-8 cursor-pointer items-center justify-center shadow hover:shadow-2xl'
          >
            <img
              src={'assets/img/arrow.svg'}
              alt='arrow'
              className={`size-6 ${toggle ? 'rotate-180' : ''} transform transition-all`}
            />
          </Link>
        </div>
        <Scrollbars>
          <div className='sidebar-inner slimscroll flex h-full flex-col'>
            <div id='sidebar-menu' className='sidebar-menu flex-1'>
              <ul ref={sidebarHorizontalRef}>
                {listMenu?.records?.slice(0, isHorizontal ? 5 : listMenu?.length).map((mainLabel, index) => (
                  <MenuItem
                    key={`menu-item-${index}`}
                    mainLabel={mainLabel}
                    openMenus={openMenus}
                    toggleMenu={toggleMenu}
                    menuFocus={menuFocus}
                    setMenuFocus={setMenuFocus}
                    handleNavigation={handleNavigation}
                    setOpenMenus={setOpenMenus}
                  />
                ))}
                {
                  isHorizontal && <li ref={moreMenuRef} id="more-menu" className='!text-black text-3xl flex justify-center items-center relative more-menu'>
                    <button onClick={() => setIsShowMoreMenu(!isShowMoreMenu)} className='!bg-[rgba(247,247,247,1)] rounded-full p-[10px] w-8 h-8 flex justify-center items-center'>
                      <MoreHorizontal className='!me-0' />
                    </button>
                    <ul style={{
                      maxHeight: isShowMoreMenu ? "500px" : 0
                    }}>
                      {listMenu?.records?.slice(5, listMenu?.length).map((mainLabel, index) => (
                        <MenuItem
                          key={`more-menu-item-${index}`}
                          mainLabel={mainLabel}
                          openMenus={openMenus}
                          toggleMenu={toggleMenu}
                          menuFocus={menuFocus}
                          setMenuFocus={setMenuFocus}
                          handleNavigation={handleNavigation}
                          setOpenMenus={setOpenMenus}
                        />
                      ))}
                    </ul>
                  </li>
                }
              </ul>
            </div>
            <div data-author='atom' className='powered-by flex items-center justify-center gap-1 px-4 pb-8'>
              <span className='text-[12px] leading-4 text-white'>Powered by</span>
              <img className='icon submenu-hdr w-auto h-5' src={'assets/img/virtrust_white_logo.svg'} alt='img' />
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

export default Sidebar