import { create } from 'zustand'

const useFormStore = create((set) => ({
  viewDataStore: {},
  actionDataStore: {},
  toolbarStore: {},
  isCheckField: false,
  formSubmitComponent: {},
  isShowingModalDetail: false,
  onSubmit: () => { },
  setViewDataStore: (viewData) => set(() => ({ viewDataStore: viewData })),
  setActionDataStore: (actionData) => set(() => ({ actionDataStore: actionData })),
  setToolbarStore: (toolbar) => set(() => ({ toolbarStore: toolbar })),
  setIsCheckField: (isCheck) => set(() => ({ isCheckField: isCheck })),
  setOnSubmit: (funcSubmit) => set(() => ({ onSubmit: funcSubmit })),
  setIsShowingModalDetail: (isShowingModalDetail) => set(() => ({ isShowingModalDetail: isShowingModalDetail })),
  setFormSubmitComponent: (key, component) => set((state) => ({
    formSubmitComponent: { ...state.formSubmitComponent, [key]: component }
  })),
  setOnSubmit: (funcSubmit) => set(() => ({ onSubmit: funcSubmit }))
}))

export default useFormStore