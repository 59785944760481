import React from 'react';
import PropTypes from 'prop-types';
import TabsView from './tabs-view';
import PaidBadged from '../../widget/PaidBadged';

const MainForm = (props) => {
    const { id, fieldList, viewData, onchangeData, resModel, context, handleOnchange, title, className } = props

    return (
        <div className={`px-6 relative py-8 rounded-[10px]  bg-white flex flex-col gap-8 min-h-20 w-full`}>
            {onchangeData?.payment_state === 'paid' && <PaidBadged />}
            <div className={`w-full grid grid-cols-2 transition-all duration-1000 ${className}`}>
                {fieldList}
            </div>
            {viewData?.views?.form?.tabs && viewData?.views?.form?.tabs?.length > 0 && <TabsView id={id} handleOnchange={handleOnchange} viewData={viewData} onchangeData={onchangeData} resModel={resModel} context={context} title={title} />}
        </div>
    )
}

MainForm.propTypes = {
    name: PropTypes.string,
    fieldList: PropTypes.array,
    active: PropTypes.bool,
}

export default MainForm