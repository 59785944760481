import { callPath } from '../../../util/constant/endpoint'
import requests from '../AxiosClient'

const ViewServices = {
  getFieldView: async ({ resModel, views, context = {}, id }) => {
    const jsonDataView = {
      model: resModel,
      method: 'get_fields_view_v2',
      kwargs: {
        views: views,
        options: {
          action_id: id,
          load_filters: true,
          toolbar: true,
        },
      },
      with_context: context
    }
    return requests.post(callPath, jsonDataView, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getMenuView: async (language) => {
    const jsonData = {
      model: 'ir.ui.menu',
      method: 'web_search_read',
      ids: [],
      with_context: {
        lang: language
      },
      kwargs: {
        specification: {
          active: {},
          name: {},
          is_display: {},
          sequence: {},
          complete_name: {},
          action: {
            fields: {
              display_name: {},
              type: {},
              binding_view_types: {},
              res_model: {},
            },
          },
          url_icon: {},
          web_icon: {},
          web_icon_data: {},
          groups_id: {
            fields: {
              full_name: {},
            },
            limit: 40,
            order: '',
          },
          display_name: {},
          child_id: {
            fields: {
              active: {},
              name: {},
              is_display: {},
              sequence: {},
              complete_name: {},
              action: {
                fields: {
                  display_name: {},
                  type: {},
                  binding_view_types: {},
                  res_model: {},
                },
              },
              url_icon: {},
              web_icon: {},
              web_icon_data: {},
              groups_id: {
                fields: {
                  full_name: {},
                },
                limit: 40,
                order: '',
              },
              display_name: {},
              child_id: {
                fields: {
                  active: {},
                  name: {},
                  is_display: {},
                  sequence: {},
                  complete_name: {},
                  action: {
                    fields: {
                      display_name: {},
                      type: {},
                      binding_view_types: {},
                      res_model: {},
                    },
                  },
                  url_icon: {},
                  web_icon: {},
                  web_icon_data: {},
                  groups_id: {
                    fields: {
                      full_name: {},
                    },
                    limit: 40,
                    order: '',
                  },
                  display_name: {},
                  child_id: {
                    fields: {
                      active: {},
                      name: {},
                      is_display: {},
                      sequence: {},
                      complete_name: {},
                      action: {
                        fields: {
                          display_name: {},
                          type: {},
                          binding_view_types: {},
                          res_model: {},
                        },
                      },
                      url_icon: {},
                      web_icon: {},
                      web_icon_data: {},
                      groups_id: {
                        fields: {
                          full_name: {},
                        },
                        limit: 40,
                        order: '',
                      },
                      display_name: {},
                      child_id: {
                        fields: {},
                        limit: 40,
                        order: '',
                      },
                    },
                    limit: 40,
                    order: '',
                  },
                },
                limit: 40,
                order: '',
              },
            },
            limit: 40,
            order: '',
          },
        },
        domain: [
          '&',
          // '&',
          ['is_display', '=', true],
          // ['child_id.action.type', 'in', ['ir.actions.act_window', false]],
          '&',
          ['active', '=', true],
          ['parent_id', '=', false],
        ]
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getViewById: async ({ id, lang }) => {
    const jsonData = {
      model: 'ir.actions.act_window',
      method: 'web_read',
      ids: [id],
      with_context: {
        lang: lang
      },
      kwargs: {
        specification: {
          view_id: {
            fields: {
              name: {},
              model: {},
            },
          },
          res_model: {},
          views: {},
          search_view_id: {},
          name: {},
          domain: {},
          context: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getSearch: async ({ id, model, views }) => {
    const jsonData = {
      model: model,
      method: 'get_fields_view_v2',
      kwargs: {
        views: views,
        options: {
          action_id: id,
          load_filters: true,
          toolbar: true,
        },
      },
    }

    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getSearchResult: async ({ model, domains, specification }) => {
    const jsonData = {
      model: model,
      method: 'web_search_read',
      kwargs: {
        specification: specification,
        offset: 0,
        order: '',
        limit: 80,
        count_limit: 10001,
        domain: domains,
      },
    }

    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getSelectionItem: async ({ model, domain, context }) => {
    const jsonData = {
      model: model,
      method: 'get_data_select',
      ids: [],
      with_context: context,
      kwargs: {
        count_limit: 10001,
        domain: domain ? domain : [],
        offset: 0,
        order: '',
        specification: {
          id: {},
          name: {},
          display_name: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getFormView: async ({ id, model, context }) => {
    const jsonData = {
      model: model,
      method: 'get_formview_action',
      ids: [id],
      with_context: context,
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

export default ViewServices
