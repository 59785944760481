import React, { useState } from 'react'
import useToolBar from '../model/toolbar'
import LeftToolbar from './LeftToolbar'
import Search from './search'
import useTableStore from '../../../store/table'
import ActionCheckbox from './ActionCheckbox'
import RightToolbar from './RightToolbar'
import { getSessionStorage } from '../../../util/util'

const Breadcrumbs = (props) => {
  const {
    id,
    vid,
    model,
    dataToolbar,
    actionData,
    viewData,
    title,
    subTitle,
    isInForm,
    funcReset,
    funcRefetch,
    loading,
    loadingDelete,
    nameActionSave,
    onDelete,
    onSubmit,
    handleOnchange,
    domain,
    setDomain,
    formSubmitRef,
    formValues,
    formSpecification,
    viewType,
    setViewType,
    openView
  } = props
  const { isShow, onClickAddNew, onClickBack } = useToolBar(vid, model, funcReset, isInForm)
  const { selectedRowKeys } = useTableStore()
  const [openModal, setOpenModal] = useState(0)
  const [openImport, setOpenImport] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const viewDataSession = getSessionStorage('viewData')
  const listTypeView = [
    {
      id: 1,
      title: "Kanban",
      type: "kanban"
    },
    {
      id: 2,
      title: "List",
      type: "list"
    },
  ]
  return (
    <>
      <div className='w-full flex items-center justify-between !pb-4 md:!pb-6 pt-2 md:!pt-8 gap-3 sm:gap-2 flex-wrap lg:gap-1'>
        <LeftToolbar
          {...props}
          title={title}
          subTitle={subTitle}
          isShow={isShow}
          setOpenImport={setOpenImport}
          setOpenModal={setOpenModal}
          dataToolbar={dataToolbar}
          openImport={openImport}
          actions={viewDataSession?.views?.form?.toolbar?.action}
          actionsPrint={viewDataSession?.views?.form?.toolbar?.print}
          funcReset={funcReset}
          funcRefetch={funcRefetch}
          formValues={formValues}
          formSpecification={formSpecification}
        />

        {!isShow &&
          (selectedRowKeys?.length > 0 && dataToolbar ? (
            <ActionCheckbox
              model={model}
              dataToolbar={dataToolbar}
              openModal={openModal}
              setOpenImport={setOpenImport}
              setOpenModal={setOpenModal}
              funcRefetch={funcRefetch}
            />
          ) : (
            <Search actionData={actionData} viewData={viewData} model={model} domain={domain} setDomain={setDomain} />
          ))
        }
        <RightToolbar
          id={id}
          isInForm={isInForm}
          dataToolbar={dataToolbar}
          onClickAddNew={onClickAddNew}
          onClickBack={onClickBack}
          title={title}
          vid={vid}
          funcReset={funcReset}
          loading={loading}
          loadingDelete={loadingDelete}
          nameActionSave={nameActionSave}
          onDelete={onDelete}
          formSubmitRef={formSubmitRef}
        />
        {openView && Object.keys(openView).length > 3 && (
          <div className='order-4 py-[5px] px-[10px] bg-[#dee2e6] border-solid border-[1px] relative cursor-pointer rounded-[8px] w-[80px] text-center' onClick={() => setOpenViewModal(!openViewModal)}>{viewType == "kanban" ? "Kanban" : "List"}
            {openViewModal && (
              <div onClick={(e) => e.stopPropagation()} className='absolute z-10 border-[#dee2e6] border-solid border-[1px] top-[38px] bg-[#fff] rounded-[8px]  left-0'>
                {listTypeView.map((item) => {
                  return (
                    <div onClick={() => {
                      setViewType(item.type)
                      setOpenViewModal(false)
                    }} className='cursor-pointer px-[12px] py-[8px] hover:bg-[#f4f6f8]' key={item.id}>{item.title}</div>)
                })}
              </div>
            )}
          </div>
        )}

      </div>

    </>
  )
}

export default Breadcrumbs
