import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import ViewServices from '../../core/services/view-service/view-service'
import useFormStore from '../../store/form'
import useHeaderStore from '../../store/header'
import Form from './form'

const FormView = () => {
  const [searchParams] = useSearchParams()
  const resModel = searchParams.get('model')
  const id = parseInt(searchParams.get('id'))
  const vid = searchParams.get('vid')
  const { lang } = useHeaderStore()

  const { setViewDataStore } = useFormStore()
  const { rootContext } = useHeaderStore()
  const formSubmitRef = useRef(null)

  // fetch action data
  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}-${lang}`, vid, id, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    refetchOnWindowFocus: false,
  })
  const actionData = actionReponse?.[0]

  const { data: viewResponse } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, actionData?.id, lang],
    queryFn: () => ViewServices.getFieldView({
      resModel: actionData?.res_model,
      views: [...actionData?.views, [actionData?.search_view_id, 'search']],
      context: rootContext
    }),
    enabled: !!actionData,
    refetchOnWindowFocus: false,
  })

  const storeData = async () => {
    try {
      if (actionReponse && actionReponse.length) {
        sessionStorage.setItem('actionData', JSON.stringify(actionReponse[0]))
      }
      sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      setViewDataStore(viewResponse)
    } catch (err) {
      console.log(err)
    }
  }

  //store data 
  useEffect(() => {
    storeData()
  }, [viewResponse])

  return (
    <div className={`page-wrapper !opacity-100`} >
      <div className='content'>
        <Form resModel={resModel} actionData={actionData} viewData={viewResponse} id={id} isDisplayBreadcrumbs={true} vid={vid} formSubmitRef={formSubmitRef} isMainForm={true} />
      </div>
    </div >
  )
}

export default FormView
