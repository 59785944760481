import React, { Fragment, useState } from 'react'
import useHeaderStore from '../../store/header'
import Form from '../../pages/form-view/form'
import { Tooltip } from 'react-tooltip'
import { X, Eye } from 'react-feather'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import ViewServices from '../../core/services/view-service/view-service'
import { useNavigate } from 'react-router-dom'
import useFormStore from '../../store/form'

const DetailForm = ({ id, vid, resModel, handleClose, context }) => {

    const navigate = useNavigate()
    const { lang } = useHeaderStore()
    const { isShowingModalDetail, setIsShowingModalDetail } = useFormStore()

    const { data: actionReponse } = useQuery({
        queryKey: [`get-form-view-${resModel}-${lang}`, id, resModel, lang],
        queryFn: () => ViewServices.getFormView({ id: id, model: resModel, context: context }),
        refetchOnWindowFocus: false,
    })

    const actionData = actionReponse

    const { data: viewResponse } = useQuery({
        queryKey: [`view-${resModel}`, actionData?.id, lang],
        queryFn: () => ViewServices.getFieldView({
            resModel: actionData?.res_model,
            views: [...actionData?.views, [actionData?.search_view_id, 'search']],
            context: context
        }),
        enabled: !!actionData,
        refetchOnWindowFocus: false,
    })

    const { action_id } = actionReponse ?? {}

    const handleNavigateDetail = () => {
        setIsShowingModalDetail(!isShowingModalDetail)
        navigate(`/form?vid=${action_id ?? vid}&model=${resModel}&id=${id}`)
    }

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleClose} aria-labelledby="modal-detail">
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto flex flex-col justify-center items-center px-5'>
                            <div className='relative z-[1] max-w-full p-4 flex flex-col gap-4 w-[1000px] transform rounded-3xl bg-[#FFF] h-[90%]'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-detail" className='text-[20px] font-semibold flex items-stretch gap-2'>
                                        Chi tiết
                                        <button onClick={handleNavigateDetail} className='text-sm italic text-primary underline'>Xem chi tiết</button>
                                    </div>
                                    <button onClick={handleClose} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>
                                <div className='flex-1 overflow-auto'>
                                    <Form resModel={resModel} actionData={actionData} viewData={viewResponse} id={id} isDisplayBreadcrumbs={false} vid={action_id ?? vid} />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

const ModalDetail = ({ idToolTip, title, resModel, idForm, vid, place, context }) => {
    const [showModalDetail, setShowModalDetail] = useState(false)
    const { isShowingModalDetail, setIsShowingModalDetail } = useFormStore()

    const handleToggleModal = (e) => {
        e.stopPropagation()
        setShowModalDetail(!showModalDetail)
        setIsShowingModalDetail(!isShowingModalDetail)
    }

    return (
        <>
            {!isShowingModalDetail && <Tooltip opacity={1} className='bg-primary p-2' place={place} id={idToolTip} clickable>
                <button className='flex gap-2 items-center justify-center rounded-lg bg-primary' type="button" onClick={handleToggleModal}>
                    <Eye size={12} />
                    {title}
                </button>
            </Tooltip>}
            {showModalDetail && <DetailForm id={idForm} vid={vid} resModel={resModel} handleClose={handleToggleModal} context={context} />}
        </>
    );
};


export default ModalDetail
