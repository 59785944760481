import React from 'react'
import useCheckbox from '../model/checkbox'
import useTableStore from '../../../store/table'
import { COMPONENT_TYPE } from '../../../util/constant/componen-type'

const TableHead = ({
  columns,
  dataSource,
  visibleOptionalFields,
  isForm,
  columnWidths,
  handleResize,
  ResizableHeader,
  showCheckBox,
  typeCo
}) => {
  const { selectedRowKeys, setSelectedRowKeys } = useTableStore()
  const { handleSelectAllChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
  const isAllSelected = selectedRowKeys?.length > 0 && selectedRowKeys.length === dataSource?.length && selectedRowKeys.every(rowItem => dataSource.some(dataItem => dataItem.id === rowItem));

  return (
    <thead>
      <tr style={{ userSelect: 'none', WebkitUserSelect: 'none' }} className='border-b-[1.5px] border-[#DEE7DD]'>
        {showCheckBox && <th
          className={`${isForm ? 'hidden' : ''} w-12 p-3 text-left font-medium uppercase text-gray-500`}
        >
          <input
            type='checkbox'
            checked={isAllSelected}
            onChange={handleSelectAllChange}
            className='ml-2 align-middle custom-checkbox'
          />
        </th>}
        {columnWidths &&
          columns?.map((col, index) => {
            return (
              <ResizableHeader
                key={'table-head-' + index}
                onResize={handleResize(index)}
                width={columnWidths[index]}
                islast={index === columns?.length - 1}
                className={`column whitespace-nowrap p-3 text-left text-sm font-medium capitalize text-[#121212]`}
              >
                {col.title}
              </ResizableHeader>
            )
          })}

        {isForm && typeCo !== COMPONENT_TYPE.TREE && <td className='relative p-2 w-[35px] '></td>}
        {visibleOptionalFields?.length > 0 && (
          <th
            className={`column z-50 sticky right-0 whitespace-nowrap bg-white px-2 text-gray-500 w-8`}
          >
            <div className='size-8'></div>
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHead
