import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const ImageField = ({ value, size = 128, bgColor = '#ffffff', fgColor = '#000000', level = 'L', string }) => {
  if(!value) return

  if(typeof value === "string") {
    return <img src={process.env.REACT_APP_DOMAIN + value} />
  }
  console.log("value image", value);
  
  return (
    <div id='qr-code'>
      {/* <QRCodeCanvas value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} /> */}
    </div>
  )
}

export default ImageField
