import { useSearchParams } from 'react-router-dom'
import { checkDomain } from '../../../core/domain/domain'
import useTableStore from '../../../store/table'
import { useEffect } from 'react'

const useTable = ({ fields, data, dataModel, model, context, tabIndex, type, typeTable }) => {

  const [searchParams] = useSearchParams()
  const vid = searchParams.get("vid")
  const { setFields, fields: fieldsTable } = useTableStore()
  const mergeFields = fields?.map((field) => {
    return { ...dataModel?.[field?.name], ...field }
  })

  useEffect(() => {
    if (mergeFields?.length > 0) {
      if (type === "many2many") {
        setFields({ ...fields, ...fieldsTable, [`${vid}_${model}_popup${type}`]: mergeFields })
      }
      else if (tabIndex === undefined) {
        setFields({ ...fields, ...fieldsTable, [`${vid}_${model}`]: mergeFields })
      }
      else {
        setFields({ ...fields, ...fieldsTable, [`${vid}_${model}_tab${tabIndex}`]: mergeFields })
      }
    }
  }, [])

  const visibleOptionalFields = mergeFields?.filter(
    (item) => item?.optional && !(item?.column_invisible ? checkDomain(context, item?.column_invisible) : false)
  )

  const dataSource = data?.map((item) => {
    const transformedItem = { ...item }

    Object.keys(item).forEach((field) => {
      if (item[field] && typeof item[field] === 'object' && item[field].display_name) {
        transformedItem[field] = item[field].display_name
      } else if (Array.isArray(item[field]) && item[field].length > 0) {
        transformedItem[field] = item[field]
      }
    })

    return item.display_name ? { ...transformedItem, item: item.display_name } : transformedItem
  })

  const handleGetColumns = () => {
    let cols = []
    try {
      cols = mergeFields
        ?.filter(
          (item) =>
            (item?.optional === 'show' || !item?.optional) &&
            !(item?.column_invisible ? checkDomain(context, item?.column_invisible) : false)
        )
        ?.map((field) => {
          return {
            key: field?.name,
            title: field?.string,
            field: { ...field },
          }
        })
    } catch (error) {
      console.log('fields:', fields)
      console.error('Error in useTable:', error)
    }

    return cols
  }

  const columns = handleGetColumns()

  return {
    dataSource,
    columns,
    visibleOptionalFields,
    typeTable,
    data
  }
}

export default useTable
