const KanbanView = ({ data, name }) => {
    if (name == "student_template") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data.map((item) => {
                    return (
                        <div className="min-w-[350px] min-h-[133px] p-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[80px] h-full flex pl-[4px]">
                                {item.image_1920 ? <img src={item.image_1920} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/avatar_grey.png" width={64} height={64} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <div>{item.name}</div>
                                <div>{item.curr_year}</div>
                                <div>{item.mobile}</div>
                                <div>{item.email}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "faculty_template") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data.map((item) => {
                    return (
                        <div className="min-w-[350px] min-h-[97px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[95px] h-full flex pl-[4px] rounded-full">
                                {item.image_1920 ? <img src={item.image_1920} width={95} height={95} alt="avatar" /> : <img src="/assets/img/avatar/avatar_grey.png" width={95} height={95} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px] p-[8px]">
                                <div className="font-bold text-[16px]">{item.name}</div>
                                <div>{item.email}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "slide_channel_template") {
        return (
            <div className={`flex sm:flex-wrap items-center pt-[16px] px-[8px] ${data.length > 2 ? "justify-center" : "justify-start"} `}>
                {data.map((item) => {
                    return (
                        <div key={item.id} className="w-full max-w-[533px] min-h-[133px] px-[16px] py-[8px] flex flex-col gap-[16px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-full h-full flex justify-between">
                                <div>{item.name}</div>
                                <div>more</div>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <div className="flex items-center">
                                    <div className="w-1/2">
                                        <button className="bg-[#ED1C24] px-[10px] py-[5px] rounded-[4px] text-[#fff] font-semibold">
                                            Xem khóa học
                                        </button>
                                    </div>
                                    <div className="w-1/2 flex justify-between">
                                        <div className="flex flex-col gap-[4px] w-full">
                                            <div className="flex items-center justify-between w-full">
                                                <div>Lượt xem</div>
                                                <div>{item.total_views}</div>
                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div>Nội dung</div>
                                                <div>{item.total_time}</div>

                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div>Thời hạn</div>
                                                <div>{item.total_slides}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex items-center pb-[8px]">
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_invited_count}</div>
                                        <div>Đã mời</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_engaged_count}</div>
                                        <div>Đang học</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_completed_count}</div>
                                        <div>Hoàn tất</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 items-center">
                                        <div>{item.members_all_count}</div>
                                        <div>Tổng</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data && data.map((item) => {
                    return (
                        <div key={item.id} className="w-full max-w-[350px] min-h-[97px] p-[8px] gap-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[64px] h-full flex rounded-full">
                                {item.books_image ? <img src={item.books_image} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/default_book.png" width={64} height={64} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <div className="font-bold text-[16px]">{item.books_code}</div>
                                <div>{item.name}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

}
export default KanbanView