import React from 'react'
import BinaryField from './BinaryField'
import DateField from './DateField'
import FileUploadDownload from './FileUploadDownload'
import Many2ManyCheckbox from './Many2ManyCheckbox'
import Many2ManyTag from './Many2ManyTag'
import Many2OneButton from './Many2OneButton'
import Many2OneField from './Many2OneField'
import TextAreaField from './TextAreaField'
import SelectDropdown from './SelectDropdown'
import CharField from '../instance-fields/CharField'
import FloatField from '../instance-fields/FloatField'
import IntegerField from '../instance-fields/IntegerField'
import ButtonBadge from './ButtonBadge'
import RadioButtonGroup from './RadioButtonGroup'
import CheckboxGroup from './CheckboxGroup'
import FloatTime from './FloatTime'
import HtmlField from './HtmlField'
import InputField from './InputField'
import MontaryField from '../instance-fields/Montary'
import ImageField from './ImageField'

const RenderWidget = (props) => {
  const {
    name,
    setValue,
    relation,
    modelsData,
    type,
    invisible,
    widget,
    value,
    string,
    methods,
    placeholder,
    required,
    readonly,
    defaultValue,
    onchangeData,
    isForm
  } = props

  switch (type) {
    case 'boolean':
      return (
        <CheckboxGroup {...props} />
      )
    case 'float':
      if (widget === 'float_time') {
        return <FloatTime {...props} />
      }
      return (
        <FloatField {...props} />
      )
    case 'char':
      if (widget === "image_url") {
        return (<ImageField value={value} string={string} />)
      }
      if (widget === "html") {
        return (
          <HtmlField {...props} type="text" />
        )
      }
      return (
        <CharField {...props} />
      )
    case 'integer':
      return (
        <IntegerField {...props} />
      )
    case 'monetary':
      return (
        <MontaryField {...props} />
      )
    case 'html':
      return (
        <InputField
          type={type}
          name={name}
          methods={methods}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          onchangeData={onchangeData}
          invisible={invisible}
        />
      )
    case 'date':
      return (
        <DateField
          {...props}
        />
      )
    case 'datetime':
      return (
        <DateField
          {...props}
        />
      )
    case 'many2one':
      return (
        <Many2OneField
          {...props}
          defaultValue={defaultValue}
        />
      )
    case 'many2one_checkbox':
      return (
        <Many2ManyCheckbox
          relation={relation}
          name={name}
          methods={methods}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
        />
      )
    case 'many2one_button':
      return (
        <Many2OneButton
          {...props}
        />
      )
    case 'many2many':
      if (widget === 'many2many_tags') {
        return (
          <Many2ManyTag
            {...props}
          />
        )
      }
      return <div />
    case 'file_upload_download':
      return (
        <FileUploadDownload
          {...props}
        />
      )
    case 'selection':
      if (widget === 'radio') {
        return (
          <RadioButtonGroup
            {...props}
          />
        )
      }
      if (widget === "badge") {
        return <ButtonBadge {...props} />
      }
      return (
        <SelectDropdown
          {...props}
          name={name}
          methods={methods}
          modelsData={modelsData}
          onchangeData={onchangeData}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
          defaultValue={defaultValue}
        />
      )
    case 'binary':
      if (widget === 'payment') {
        return <></>
      }
      return (
        <BinaryField
          {...props}
        />
      )
    case 'text':
      return (
        <TextAreaField
          {...props}
        />
      )
    case 'barcode':
      return (
        <Barcode value={'example-value'} background='transparent' />
      )

    default:
      return null
  }
}

export default RenderWidget
