import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONContext, evalJSONDomain } from '../../util/util'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import useHeaderStore from '../../store/header'
import useSidebar from '../../store/sidebar'
import ModalDetail from '../general/ModalDetail'
import useSelectStore from '../../store/select'

const Many2OneField = ({
  index,
  relation,
  name,
  methods,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  isForm,
  domain,
  defaultValue,
  context,
  onchangeData,
  handleOnchange
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const actionDataString = sessionStorage.getItem("actionData");
  const { menuList } = useSidebar()
  const actionId = menuList?.flatMap(item =>
    item?.child_id.filter(childItem =>
      childItem?.is_display && childItem?.action?.res_model === relation
    )
  )?.[0]?.action?.id;

  const actionData = actionDataString && actionDataString !== "undefined"
    ? JSON.parse(actionDataString)
    : {};
  const _context = { ...onchangeData, ...evalJSONContext(actionData?.context) || {} }
  const domainObject = evalJSONDomain(domain, _context)
  const contextObject = evalJSONContext(context, _context) || {}
  const { rootContext } = useHeaderStore()
  const { setListSubject } = useSelectStore()

  const { t } = useTranslation()
  const { data: dataOfSelection } = useQuery({
    queryKey: [`data_${relation}`, domainObject],
    queryFn: () => ViewServices.getSelectionItem({
      model: relation,
      domain: domainObject,
      context: { ...contextObject, ...rootContext }
    }),
    refetchOnWindowFocus: false
  })

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.name || val.display_name,
    })) || []

  useEffect(() => {
    const initialValue = methods?.getValues(name);
    if (initialValue) {
      setSelectedValue(initialValue);
    }
    if (relation === 'student.subject') setListSubject(options)
  }, []);

  if (!isForm) {
    const defaultValueOption = options.find((option) => option.value === defaultValue)

    return <>
      <ModalDetail idToolTip={name + index} title={"Chi tiết"} idForm={defaultValueOption?.value} resModel={relation} vid={actionId?.id} context={{ ...contextObject, ...rootContext }} place='top-start' />
      <span data-tooltip-id={name + index} id={name}>{defaultValueOption?.label}</span>
    </>
  } else {
    return (
      <>
        {selectedValue && <ModalDetail idToolTip={name} title={"Chi tiết"} idForm={selectedValue} resModel={relation} vid={actionId?.id} context={{ ...contextObject, ...rootContext }} place='top-start' />}
        <div id={name} data-tooltip-id={name} className='inline-block w-full'>
          <Controller
            name={name}
            control={methods.control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  options={options}
                  classNames={{
                    control: () => isForm ? "field" : "field-in-tree",
                    valueContainer: () => 'm-0 p-0',
                    singleValue: () => 'm-0',
                    input: () => 'm-0 p-0',
                    dropdownIndicator: () => 'm-0 p-0'
                  }}
                  placeholder={placeholder || t(`choose_place`)}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption.value)
                    handleOnchange(name, selectedOption.value)
                  }}
                  value={options.find((option) => option.value === field.value) || null}
                  isSearchable={!readonly}
                  isDisabled={readonly}
                  required={!invisible && required}
                  menuPlacement='auto'
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: !isForm && 'unset',
                      borderWidth: !isForm && 0,
                      backgroundColor: !isForm && 'transparent',
                    })
                  }}
                />
              )
            }}
          />
        </div>
      </>
    )
  }
}

export default Many2OneField
