import viLocale from '@fullcalendar/core/locales/vi';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ViewServices from '../../core/services/view-service/view-service';
import useHeaderStore from '../../store/header';
import ModalCalendar from '../modal/ModalCalendar';
import { format } from 'date-fns';
import { evalJSONContext } from '../../util/util';

// generate events
const eventsTest = [
  { title: 'event 1', start: '2024-11-11T10:00:00', end: '2024-11-11T12:00:00' },
  { title: 'event 1', start: '2024-11-11T10:00:00', end: '2024-11-11T12:00:00' },
];

export default function Calendar({ events, tabsData, id, }) {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [date, setDate] = useState(tabsData?.[0])
  const viewData = JSON.parse(sessionStorage.getItem('viewData'))
  const { rootContext, } = useHeaderStore()
  const notebookViewMode = viewData?.views?.form?.notebook_view_mode?.calendar
  const [itemId, setItemId] = useState(null)

  const { data: listView } = useQuery({
    queryKey: [`view-${notebookViewMode?.res_model}`,],
    queryFn: () => ViewServices.getFieldView({
      resModel: notebookViewMode?.res_model,
      views: [notebookViewMode?.views],
      context: rootContext
    }),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleSelect = (info) => {
    console.log('info', info)

    const isDateStart = listView?.views?.calendar?.date_start
    const isDateStop = listView?.views?.calendar?.date_stop

    if (isDateStart && isDateStop) {
      setDate({
        [listView?.views?.calendar?.date_start]: format(info?.startStr, 'HH'),
        [listView?.views?.calendar?.date_stop]: format(info?.endStr, 'HH'),
      })
    } else {
      const dateField = listView?.views?.calendar?.fields?.find(field => evalJSONContext(field.options)?.type === 'date')
      const weekdayField = listView?.views?.calendar?.fields?.find(field => evalJSONContext(field.options)?.type === 'week')

      const result = {
        // faculty_id: null,
        // period_id: null,
        // subject: null,
        // date: format(info?.startStr, 'dd/MM/yyyy'),
        // week_day: new Date(info?.startStr)?.getDay()?.toString(),
        time_from: format(info?.startStr, 'HH'),
        time_till: format(info?.endStr, 'HH'),
      }
      console.log('info', evalJSONContext(dateField?.options), dateField?.name, format(info?.startStr, 'yyyy-MM-dd'))
      if (dateField) result[dateField?.name] = format(info?.startStr, 'yyyy-MM-dd')
      if (weekdayField) result[weekdayField?.name] = new Date(info?.startStr)?.getDay()?.toString()
      setDate(result)
    }

    if (listView?.views?.calendar?.quick_create) {
      handleOpenModal()
    }
  };

  const handleEventChange = (info) => {
    setItemId(info?.event?._def?.extendedProps?.itemId)
    handleOpenModal()
  };

  return (
    <div className="custom-calendar-header">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        select={info => handleSelect(info)}
        events={events}
        eventClick={(info) => handleEventChange(info)}
        locale={viLocale} // Set the locale to Vietnamese
      />

      {isOpenModal && <ModalCalendar id={itemId} resModel={notebookViewMode?.res_model} initialMappingData={date} rootContext={rootContext} relationMappingData={{ [notebookViewMode?.related_field]: id }} quickCreateViewId={listView?.views?.calendar?.quick_create_view_id} setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />}
    </div>
  );
}